/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import '@bricks/style-dictionary-release/build/scss/pacas.scss'; */
.title-modal-datail {
	@apply text-neutral-dark text-xl font-medium font-['Montserrat'] leading-[30px];
}

.subtitle-modal-datail-ligth {
	@apply text-zinc-400 text-base font-medium font-['Montserrat'] leading-normal;
}
.subtitle-modal-datail-dark {
	@apply text-neutral-dark text-base font-medium font-['Montserrat'];
}
.subtitle-modal-datail-primary {
	@apply text-primary-medium text-base font-medium font-['Montserrat'];
}
.content-inf-detail {
	@apply inline-flex flex-col items-start justify-start gap-4;
}

.icon-primary-modal {
	@apply text-lg text-primary-medium;
}
.gap-row{
    @apply inline-flex items-center justify-start gap-2
}
.error-message{
    @apply text-critical-medium p-1 text-sm w-96
}

#p-content-table::-webkit-scrollbar-thumb {
	@apply rounded-md py-14 px-14 bg-secondary-medium;
}

#p-content-table::-webkit-scrollbar {
	@apply h-[9px] w-[14px];
}

#p-content-table::-webkit-scrollbar-track {
	@apply bg-transparent;
}
